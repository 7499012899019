<app-header [session]="session">
</app-header>
<main class="background-color-white main-container">
    <div class="bolt-container print-width">
        <div class="bolt-row wrapper">
            <div *ngIf="session || !showNavigation" class="bolt-col bolt-col-lg-12 bolt-heading-sm main-heading noprint bolt-space-bottom-lg">
                <h1 *ngIf="!hideTitle">{{title}}</h1>
            </div>
            <div *ngIf="showNavigation && session" class="bol-col bolt-col-lg-3 noprint">
                <app-side-nav [session]="session"></app-side-nav>
            </div>
            <div *ngIf="showNavigation && session" class="bolt-col bolt-col-lg-9">
                <router-outlet></router-outlet>
            </div>
            <div *ngIf="!showNavigation && session && deepLink" class="bolt-col bolt-col-lg-12 bolt-col-sm-12">
                <router-outlet></router-outlet>
            </div>
            <div *ngIf="!showNavigation && session && !deepLink" class="bolt-col bolt-col-lg-12 bolt-col-sm-12">
                <router-outlet></router-outlet>
            </div>
            <div *ngIf="!showNavigation && !session && !deepLink" class="bol-col bolt-col-lg-12 bolt-col-sm-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</main>
<tpi-notification-modal *ngIf="sessionExpireWarningModalShow">
    <ng-container modalWarning *ngIf="sessionExpireWarningModalShow">
        <bolt-modal primarybutton="Keep working" secondarybutton="Log out" (bolt-modal-primary-button-click)="closeModal()" (bolt-modal-secondary-button-click)="redirectToLogoutPage()"
            id="warningModal" heading="Do you want to keep working?" open=true>
            <p>
                For your security, your Nationwide online session will expire in approximately 3 minutes. What would you
                like to do?
            </p>
        </bolt-modal>
    </ng-container>
</tpi-notification-modal>
<footer *ngIf="session || !showNavigation">
    <app-footer>
    </app-footer>
</footer>