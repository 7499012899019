import { DatePipe } from '@angular/common';
import {
    HttpClient,
    HttpClientModule, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { SplunkService } from '@nationwide/client-logging';
import { StoreModule } from '@ngrx/store';
import { AuthModule, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PipesModule } from './shared/pipes/pipes.module';
import { TpiOidcAuthConfigService } from './shared/services/tpi-oidc-auth-config.service';
import { ValidatorService } from './shared/services/validator.service';
import { FeedbackComponent } from './tpi-components/feedback/feedback.component';
import { FooterComponent } from './tpi-components/footer/footer.component';
import { HeaderComponent } from './tpi-components/header/header.component';
import { LoginLogoutModule } from './tpi-components/login-logout/login-logout.module';
import { ManageAccountsCompanyWebModule } from './tpi-components/manage-accounts-company-web/manage-accounts-company-web.module';
import { NotificationModalComponent } from './tpi-components/modals/notification-modal/notification-modal.component';
import { MortgageBulkUpdateModule } from './tpi-components/mortgage-bulk-update/mortgage-bulk-update.module';
import { PolicyInquiryModule } from './tpi-components/policy-inquiry/policy-inquiry.module';
import { SideNavComponent } from './tpi-components/side-nav/side-nav.component';
import { UserCredentialsModule } from './tpi-components/user-credentials/user-credentials.module';
import { updateSearchFieldsReducer } from './tpi-reducers/policysearch.reducer';
import { updateSelectedCompanyFieldsReducer } from './tpi-reducers/selected-company.reducer';
import { updateUserFieldsReducer } from './tpi-reducers/user-fields.reducer';
import { RequestHeaderInterceptorService } from './tpi-services/interceptors/request-header-interceptor.service';
import { RequestResponseLoggingInterceptorService } from './tpi-services/interceptors/request-response-logging-interceptor.service';
import { RequestTimeoutInterceptorService } from './tpi-services/interceptors/request-timeout-interceptor.service';
import { LoggerService } from './tpi-services/logger/logger.service';
import { SessionService } from './tpi-services/tpi-session/session.service';
import { ThirdPartyInquiryErrorHandler } from './tpi-services/third-party-inquiry-error-handler';

const authFactory = (configService: TpiOidcAuthConfigService): StsConfigStaticLoader => {
    const config = configService.getConfig();
    return new StsConfigStaticLoader(config);
};

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        PolicyInquiryModule,
        ManageAccountsCompanyWebModule,
        UserCredentialsModule,
        MortgageBulkUpdateModule,
        AppRoutingModule,
        PipesModule,
        LoginLogoutModule,
        StoreModule.forRoot({
            storeSearchFields: updateSearchFieldsReducer,
            storeSelectedCompanyFields: updateSelectedCompanyFieldsReducer,
            storeUserFields: updateUserFieldsReducer
        }),
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: authFactory,
                deps: [TpiOidcAuthConfigService]
            }
        })
    ],
    declarations: [
        AppComponent,
        FeedbackComponent,
        HeaderComponent,
        SideNavComponent,
        FooterComponent,
        NotificationModalComponent
    ],

    providers: [
        { provide: 'policyRequestsConfig', useValue: environment },
        {
            provide: HTTP_INTERCEPTORS, useClass: RequestHeaderInterceptorService, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: RequestTimeoutInterceptorService, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: RequestResponseLoggingInterceptorService, multi: true
        },
        { provide: 'document', useValue: document },
        { provide: 'window', useValue: window },
        { provide: ErrorHandler, useClass: ThirdPartyInquiryErrorHandler },
        SessionService,
        LoggerService,
        SplunkService,
        DatePipe,
        ValidatorService,
        HttpClient,
        Title
    ],
    exports: [
        NotificationModalComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
