import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LoggerService } from './logger/logger.service';

@Injectable()
export class ThirdPartyInquiryErrorHandler implements ErrorHandler {
    constructor(
        // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
        private injector: Injector
    ) { }
    handleError(error): void {
        const loggerService = this.injector.get(LoggerService);
        try {
            loggerService.error('ERROR HANDLER', { message: error.message, stack: error.stack });
        } catch (caughtError) {
            loggerService.error('Exception in error handler', caughtError);
        }
        loggerService.logCurrentContents();
    }
}
