import { tpiSearchError } from './tpi-search-errors';
import { tpiSearchSuccess } from './tpi-search-success';

export const tpiManageUsers = {
    ALL_ACCOUNT: {
        roleDescription: 'All',
        id: 0
    },
    ADMIN_ACCOUNT: {
        roleDescription: 'Admin',
        id: 1
    },
    AGENT_ACCOUNT: {
        roleDescription: 'Agent',
        id: 2
    },
    SUPER_USER_ACCOUNT: {
        roleDescription: 'Superuser',
        id: 3
    },
    WEB_SUPPORT_ACCOUNT: {
        roleDescription: 'Web Support',
        id: 6
    },
    ACCOUNTS: [
        {
            roleDescription: 'All',
            id: 0
        },
        {
            roleDescription: 'Admin',
            id: 1
        },
        {
            roleDescription: 'Agent',
            id: 2
        },
        {
            roleDescription: 'Superuser',
            id: 3
        },
        {
            roleDescription: 'Web Support',
            id: 6
        }
    ],
    SELECT_ALL: 'Select all',
    DESELCT_ALL: 'Deselect all',
    LIMIT_USERS_LIST: 10,
    TEMPORARY_PASSWORD_EXPIRE: '48 hours',
    ADD_NEW_USER_ALREADY_EXISTS_ERROR: 'USER_ALREADY_EXISTS',
    BY_ID: ['All', 'Admin', 'Agent', 'Superuser', 'Web Support']
};

export const tpiModifyUserInputs = {
    MODIFY_USER_INPUT_DELETE: {
        modalText: `Are you sure you want to delete your selection? This action can't be undone.`,
        modalHeading: 'Delete user',
        modalButtonText: 'Delete user',
        successModalText: 'Your selection has been removed from our system. If you wish to add deleted users back into the system, you will need to reenter their information manually.',
        partialSuccessMessage: tpiSearchSuccess?.MODIFY_USER_PARTIAL_SUCCESS.replace('@@action@@', 'deleted'),
        errorMessage: tpiSearchError?.MODIFY_USER_ERROR.replace('@@action@@', 'delete'),
        errorMessageExceedLimit: tpiSearchError?.MODIFY_USER_EXCEED_LIMIT_ERROR.replace('@@action@@', 'delete').replace('@@limit@@', tpiManageUsers.LIMIT_USERS_LIST.toString())
    },
    MODIFY_USER_INPUT_UNLOCK: {
        modalText: `Are you sure you want to unlock the user(s) you've selected? Once unlocked, they will be able to log in immediately.`,
        modalTextWebSupport: 'Are you sure you want to unlock the user you\'ve selected? Once unlocked, they will be able to log in immediately.',
        modalHeading: 'Unlock user access',
        modalButtonText: 'Unlock',
        successModalText: 'They are now free to log in as usual.',
        partialSuccessMessage: tpiSearchSuccess?.MODIFY_USER_PARTIAL_SUCCESS.replace('@@action@@', 'unlocked'),
        errorMessage: tpiSearchError?.MODIFY_USER_ERROR.replace('@@action@@', 'unlock'),
        errorMessageExceedLimit: tpiSearchError?.MODIFY_USER_EXCEED_LIMIT_ERROR.replace('@@action@@', 'unlock').replace('@@limit@@', tpiManageUsers.LIMIT_USERS_LIST.toString())
    },
    MODIFY_USER_INPUT_RESET: {
        modalText: `Are you sure you want to reset the password for your selection? If so, a temporary password that will expire in ${tpiManageUsers.TEMPORARY_PASSWORD_EXPIRE} will be emailed to any user(s) you've selected.`,
        modalTextWebSupport: `Are you sure you want to reset the password for your selection? If so, a temporary password that will expire in ${tpiManageUsers.TEMPORARY_PASSWORD_EXPIRE} will be emailed to any user you've selected.`,
        modalHeading: 'Reset user password',
        modalButtonText: 'Reset password',
        successModalText: `A new, temporary password has been emailed to your selected user(s). They will need to log in to create a new password within ${tpiManageUsers.TEMPORARY_PASSWORD_EXPIRE}.`,
        successModalTextWebSupport: `A new, temporary password has been emailed to your selected user. They will need to log in to create a new password within ${tpiManageUsers.TEMPORARY_PASSWORD_EXPIRE}.`,
        partialSuccessMessage: tpiSearchSuccess?.MODIFY_USER_PARTIAL_SUCCESS.replace('@@action@@', 'reset'),
        errorMessage: tpiSearchError?.MODIFY_USER_ERROR.replace('@@action@@', 'reset'),
        errorMessageExceedLimit: tpiSearchError?.MODIFY_USER_EXCEED_LIMIT_ERROR.replace('@@action@@', 'reset').replace('@@limit@@', tpiManageUsers.LIMIT_USERS_LIST.toString())
    }
};

export const enum ModifyUserActions {
    Delete = 'DELETE',
    Unlock = 'UNLOCK',
    Reset = 'RESET',
    Success = 'SUCCESS'
}

export const enum TpiManageUsersPagePaths {
    Websupport = 'web-support',
    CompanyList = 'company-list',
    ManageUsers = 'manage-users',
    Default = 'policy-search'
}
