import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { commonConstants } from '../../../CONSTANTS/tpi-common-constants';
import { tpiErrorTags } from '../../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../../CONSTANTS/tpi-search-errors';
import { AutoAddressSearchRequest } from '../../../tpi-models/general/auto-sddress-search-request';
import { Window } from '../../../tpi-models/general/model-window';
import { PolicyEmitters } from '../../../tpi-models/general/policy-information';
import { SearchFields } from '../../../tpi-models/general/search-forms';
import { TPISession } from '../../../tpi-models/general/tpisession';
import { LoggerService } from '../../../tpi-services/logger/logger.service';
import { PolicySearchService } from '../../../tpi-services/tpi-policy-inquiry/policy-search.service';
import { SessionService } from '../../../tpi-services/tpi-session/session.service';
import { PolicySearchStateServiceService } from '../../../tpi-services/tpi-statemanagement/policy-search-state-service.service';
import { UtilsService } from '../../../tpi-services/utility/utils-service.service';
@Component({
    selector: 'app-button-bar',
    templateUrl: './button-bar.component.html',
    styleUrls: ['./button-bar.component.css']
})
export class ButtonBarComponent implements OnInit {
    @Input() set searchFormValues(searchForm: UntypedFormGroup) {
        this.searchForm = searchForm;
        const values = searchForm.value;
        this.setSearchFieldValues(values);
    }
    @Input() disablePrint: boolean;
    @Output() disablePrintChange: EventEmitter<boolean> = new EventEmitter();
    @Input() isActiveTab: string;
    @Output() performValidationEmit = new EventEmitter<boolean>();
    @Output() resetSearchForm = new EventEmitter<boolean>();
    @Output() setSearch: EventEmitter<SearchFields> = new EventEmitter();
    @Output() showResults = new EventEmitter<boolean>();

    isAddressSearch: boolean;
    isResetNoSearch: boolean;
    isResetYesSearch: boolean;
    isSearch: boolean;
    searchFields: SearchFields = {
        policyNumber: '',
        vin: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        fullVin: '',
        hasError: false,
        errorMessage: '',
        invalidForm: true,
        isYesSearch: false,
        isNoSearch: false,
        isPropertySearch: true,
        action: {
            displayResults: false,
            vin: '',
            policyNumber: ''
        }
    };
    searchForm: UntypedFormGroup;
    session: TPISession;
    /**
     * @constructor
     * @param {GisService} gisService : standardize address service
     * @param {EcifService} ecif : customer acquisition service
     * @param {VehicleRequestsService} vehicleRequestsService : ISP sevice (package) to retrieve auto vehicle
     * @param {LoggerService} loggerService : splunk logger
     * @param {UtilsService} utilsService : helper functions service
     */
    // eslint-disable-next-line max-params
    constructor(
        public loggerService: LoggerService,
        public utilsService: UtilsService,
        private sessionService: SessionService,
        private policySearchService: PolicySearchService,
        @Inject('window') private window: Window,
        private storeSearchFields: PolicySearchStateServiceService
    ) { }

    /**
     * set the error message content
     * @param {string} errorMessage : Error message code
     * @protected
     * @return {void}
     */
    displaySearchResultError(errorMessage: string): void {
        this.loggerService.debug('displaySearchResultError start', {
            errorMessage
        });
        if (errorMessage === tpiErrorTags.INVALID_ZIPCODE) {
            this.searchFields.errorMessage = tpiSearchError.INVALID_ZIP_CODE_ERROR;
        } else if (errorMessage === tpiErrorTags.INVALID_VIN || errorMessage === undefined) {
            this.searchFields.errorMessage = tpiSearchError.INVALID_VIN_ERROR;
        } else {
            this.searchFields.errorMessage = tpiSearchError.UNKNOWN_APPLICATION_ERROR;
        }
        this.loggerService.error('displaySearchResultError method output', {
            errorMessage,
            errorMessageDisplayed: this.searchFields.errorMessage
        });
        this.storeSearchFields.updateSearchFields(this.searchFields);
    }

    /**
     * hide wait panel
     * @protected
     * @return {void}
     */
    hideWaitIndicator(): void {
        this.window.waitIndicatorFullscreen.hide();
    }

    logDistinctAutoSearch(): void {
        const lastSixDigitsVin = -6;
        let policyVin;
        if (this.searchFields.vin.length === 0) {
            policyVin = this.searchFields.fullVin.slice(lastSixDigitsVin);
            this.loggerService.debug('TPI-PLDS-V2: Last 6 digits of full vin ', {
                UserId: sessionStorage.getItem('userName'),
                PolicyNumber: this.searchFields.policyNumber,
                Vin: policyVin,
                SearchField: this.searchFields
            });
        } else {
            policyVin = this.searchFields.vin;
        }
        // Log for Total Number of Policy Searches (AUTO)
        this.loggerService.info('TPI-PLDS-V2: Start submitSearch() -Auto Policy-Vin and Address Search', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.searchFields.policyNumber,
            Vin: policyVin,
            SearchField: this.searchFields
        });
    }

    ngOnInit(): void {
        this.isAddressSearch = false;
        this.isResetYesSearch = false;
        this.isResetNoSearch = false;
        this.disablePrint = true;
        this.disablePrintChange.emit(this.disablePrint);
        this.session = this.sessionService.getSession();
        this.storeSearchFields.getSearchFields().subscribe((searchResults) => {
            this.searchFields = searchResults;
        });
    }

    /**
     * Display wait panel
     * @protected
     * @return {void}
     */
    openWaitIndicator(): void {
        this.window.waitIndicatorFullscreen.show();
    }

    policySearchReset(): void {
        this.disablePrint = true;
        this.resetSearchForm.emit(true);
    }

    /**
     * policy inquiry print functionality
     * @protected
     * @return {void}
     */
    printSearch(): void {
        this.loggerService.info('Select Print button on Policy Page', {
            UserId: sessionStorage.getItem('userName'),
            PolicyNumber: this.searchFields.policyNumber,
            PolicySystem: 'Policy Center',
            LOB: this.isActiveTab === commonConstants.PROPERTY_FLOW ? commonConstants.PROPERTY_FLOW : commonConstants.AUTO_FLOW
        });
        this.utilsService.printScreen();
    }

    setAutoEmitters(): PolicyEmitters {
        return {
            autoSearchEmit: this.setSearch,
            showResultEmit: this.showResults,
            disablePrintChange: this.disablePrintChange,
            autoSearchFields: this.searchFields,
            session: this.session
        };
    }

    setPropertyEmitters(): PolicyEmitters {
        return {
            propertySearchEmit: this.setSearch,
            showPropertySearchEmit: this.showResults,
            disablePrintChange: this.disablePrintChange,
            autoSearchFields: this.searchFields,
            session: this.session
        };
    }

    setSearchFieldValues(values): void {
        this.searchFields = {
            policyNumber: values.policyNumber?.replace(' ', '') || '',
            vin: values.sixDigitVinNumber || '',
            streetAddress: values.streetAddress || '',
            city: values.city || '',
            state: values.state || '',
            zipCode: values.zipCode || '',
            fullVin: values.fullVin || '',
            hasError: false,
            errorMessage: '',
            invalidForm: true,
            isYesSearch: false,
            isNoSearch: false,
            isPropertySearch: true,
            action: {
                displayResults: false,
                vin: values.sixDigitVinNumber,
                policyNumber: values.policyNumber
            }
        };
        this.setConditionalFieldValues(this.searchForm);
    }

    submitSearch(): void {
        this.loggerService.debug('submitSearch method start', {
            searchFields: this.searchFields,
            flow: this.isActiveTab
        });
        this.disablePrint = true;
        this.disablePrintChange.emit(this.disablePrint);
        this.performValidationEmit.emit(true);
        this.setSearchFieldValues(this.searchForm.value);
        this.showResults.emit(false);
        if (!this.searchFields.invalidForm) {
            this.openWaitIndicator();
            if (this.isActiveTab === commonConstants.PROPERTY_FLOW) {
                this.startPropertySearch();
            } else {
                this.startAutoSearch();
            }
        }
    }

    private setConditionalFieldValues(form: UntypedFormGroup): void {
        this.searchFields.invalidForm = form.invalid;
        this.searchFields.isYesSearch = form.value.hasPolicyNumber === '1';
        this.searchFields.isNoSearch = form.value.hasPolicyNumber === '2';
        this.searchFields.isPropertySearch = form.value.hasPolicyNumber === undefined;
    }

    private startAutoSearch(): void {
        this.logDistinctAutoSearch();
        if (this.searchFields.isNoSearch) {
            const fullAddress = `${this.searchFields.streetAddress} ${this.searchFields.city} ${this.searchFields.state}`;
            this.loggerService.info('TPI-PLDS-V2: Start getAutoPolicyNumberAddressSearch', {
                UserId: sessionStorage.getItem('userName'),
                PolicyNumber: this.searchFields.policyNumber,
                Address: fullAddress,
                Vin: this.searchFields.vin,
                ShowResults: this.searchFields.action.displayResults
            });
            const autoAddressSearchRequest: AutoAddressSearchRequest = {
                street: this.searchFields.streetAddress,
                city: this.searchFields.city,
                state: this.searchFields.state,
                zipCode: this.searchFields.zipCode,
                addressLimit: commonConstants.AUTO.AUTO_SEARCH_SERVICE_REQUEST.ADDRESS_LIMIT,
                customerType: commonConstants.AUTO.AUTO_SEARCH_SERVICE_REQUEST.CUSTOMER_TYPE,
                depth: commonConstants.AUTO.AUTO_SEARCH_SERVICE_REQUEST.DEPTH,
                returnEmail: commonConstants.AUTO.AUTO_SEARCH_SERVICE_REQUEST.RETURN_EMAIL
            };
            this.policySearchService.getAutoPolicyNumberAddressSearch(
                autoAddressSearchRequest,
                this.searchFields.fullVin,
                this.setAutoEmitters()
            );
        } else {
            this.loggerService.info('TPI-PLDS-V2: Start getAutoPolicyNumberPolicyVinSearch', {
                UserId: sessionStorage.getItem('userName'),
                PolicyNumber: this.searchFields.policyNumber,
                Vin: this.searchFields.vin,
                ShowResults: this.searchFields.action.displayResults
            });
            this.policySearchService.getAutoPolicyNumberPolicyVinSearch(
                this.searchFields.policyNumber,
                this.searchFields.vin,
                this.setAutoEmitters()
            );
        }
    }

    private startPropertySearch(): void {
        this.policySearchService.getPropertySearchResults(
            this.searchFields.policyNumber,
            this.searchFields.zipCode,
            this.setPropertyEmitters()
        );
    }
}
