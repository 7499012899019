import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { tpiErrorTags } from '../../CONSTANTS/tpi-error-tags';
import { tpiSearchError } from '../../CONSTANTS/tpi-search-errors';
import { EcifService } from '../../service/ecif.service';
import { AutoAddressSearchRequest } from '../../tpi-models/general/auto-sddress-search-request';
import { CustomerAgreement } from '../../tpi-models/general/ecifResponse';
import { Window } from '../../tpi-models/general/model-window';
import { PolicyEmitters } from '../../tpi-models/general/policy-information';
import { SearchFields } from '../../tpi-models/general/search-forms';
import { LoggerService } from '../logger/logger.service';
import { PolicySearchStateServiceService } from '../tpi-statemanagement/policy-search-state-service.service';
import { PolicySearchAdapterService } from './policy-search-adapter.service';

@Injectable({
    providedIn: 'root'
})
export class PolicySearchService {
    autoSearchFields: SearchFields;
    disablePrint: boolean;
    fullVin: string;

    // eslint-disable-next-line max-params
    constructor(
        private loggerService: LoggerService,
        private ecif: EcifService,
        private policySearchAdapter: PolicySearchAdapterService,
        @Inject('window') private window: Window,
        private storeStateSearchFields: PolicySearchStateServiceService
    ) { }

    getAutoPolicyNumberAddressSearch(autoAddressSearchRequest: AutoAddressSearchRequest, fullVin: string, emitters: PolicyEmitters): void {
        this.loggerService.info('getAutoPolicyNumberAddressSearch() - Enter', {
            autoAddressSearchRequest,
            fullVin,
            emittersAutoSearchFields: emitters.autoSearchFields,
            emittersSession: emitters.session,
            emittersPropertySearchEmit: emitters.propertySearchEmit,
            emittersShowPropertySearchEmit: emitters.showPropertySearchEmit,
            autoSearchFields: this.autoSearchFields
        });

        this.autoSearchFields = emitters.autoSearchFields;
        emitters.showResultEmit.emit(false);

        this.loggerService.info('getAutoPolicyNumberAddressSearch() - initalized autoSearchFields', {
            autoSearchFields: this.autoSearchFields
        });

        this.ecif.validateAutoAddressEcn(autoAddressSearchRequest).pipe(
            switchMap((ecifCustomerNumbers: Array<string>) => {
                const validateAddress: Observable<any>[] = [];
                for (const customerNumber of ecifCustomerNumbers) {
                    validateAddress.push(this.ecif.validationAutoAddressEcnPolicyNumber(customerNumber).pipe(
                        switchMap((policyNums: Array<string>) => {
                            this.loggerService.info('getAutoPolicyNumberAddressSearch() - validationAutoAddressEcnPolicyNumber() ecn fetched policy numbers', { policyNums });

                            const validatePolicyNumbers: Observable<any>[] = [];
                            for (const policyNumber of policyNums) {
                                validatePolicyNumbers.push(this.ecif.validateAutoAddressVin(policyNumber, fullVin));
                            }

                            return forkJoin(validatePolicyNumbers).pipe(
                                switchMap((validateVins: any[]) => {
                                    this.loggerService.info('getAutoPolicyNumberAddressSearch() - validateAutoAddressVin()[forkJoin] vin validation responses', validateVins);

                                    const validPolicy = validateVins.find((response) => {
                                        if (response.validVin === true) {
                                            this.autoSearchFields.action.policyNumber = response.policyNumber?.replace('%20', ' ');
                                            this.autoSearchFields.action.vin = fullVin;

                                            this.loggerService.info('getAutoPolicyNumberAddressSearch() - validateAutoAddressVin()[forkJoin] valdate addtess vin with policy number; updated autoSearchFields[action].policyNumber and autoSearchFields[action].vin', {
                                                response,
                                                validateVins,
                                                autoSearchFields: this.autoSearchFields
                                            });
                                            return true;
                                        }
                                        return false;
                                    });
                                    return of(!!validPolicy);
                                })
                            );
                        }),
                        catchError((error) => {
                            this.loggerService.error('Auto policy number with System-type of PolicyCenter not found for ECN', error);

                            const systemErrorCode = 500;
                            if (error.status !== systemErrorCode) {
                                return of(false);
                            }
                            return throwError(() => error);
                        })
                    ));
                }
                return forkJoin(validateAddress).pipe(
                    switchMap((vinMatches: any[]): Observable<boolean> => {
                        this.loggerService.info('getAutoPolicyNumberAddressSearch() - validationAutoAddressEcnPolicyNumber()[forkJoin] - ecn responses with valid policy and vin', vinMatches);

                        return of(vinMatches.includes(true));
                    })
                );
            })
        ).subscribe({
            next: (isValid: boolean) => {
                this.loggerService.info('getAutoPolicyNumberAddressSearch() - enter subscribe[next]', {
                    isValid,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });

                if (isValid) {
                    this.loggerService.info('getAutoPolicyNumberAddressSearch() - subscribe[next] auto address and vin validated', {
                        isValid,
                        emittersAutoSearchFields: emitters.autoSearchFields,
                        emittersSession: emitters.session,
                        emittersPropertySearchEmit: emitters.propertySearchEmit,
                        emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                    });

                    this.autoSearchFields.action.displayResults = true;
                    this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                    emitters.autoSearchEmit.emit();
                    this.disablePrint = false;
                    emitters.disablePrintChange.emit(this.disablePrint);

                    this.loggerService.info(`getAutoPolicyNumberAddressSearch() - subscribe[next] validate auto vin for policy-vin search; updated autoSearchFields[hasError, errorMessage]`, {
                        isValid,
                        emittersAutoSearchFields: emitters.autoSearchFields,
                        emittersSession: emitters.session,
                        emittersPropertySearchEmit: emitters.propertySearchEmit,
                        emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                    });

                    this.loggerService.info('TPI-PLDS-V2: Successful Search Auto [address]', {
                        UserId: emitters.session.context.session.account.shortName,
                        PolicyNumber: this.autoSearchFields.action.policyNumber,
                        Vin: this.autoSearchFields.action.vin
                    });
                } else {
                    this.loggerService.error('getAutoPolicyNumberAddressSearch() - subscribe[next] valid address invalid valid vin', {
                        isValid,
                        emittersAutoSearchFields: emitters.autoSearchFields,
                        emittersSession: emitters.session,
                        emittersPropertySearchEmit: emitters.propertySearchEmit,
                        emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                    });

                    this.disablePrint = true;
                    emitters.disablePrintChange.emit(this.disablePrint);
                    this.autoSearchFields.errorMessage = tpiSearchError.ADDRESS_OR_POLICY_NOT_FOUND;
                    this.autoSearchFields.hasError = true;

                    this.loggerService.error(`getAutoPolicyNumberAddressSearch() - subscribe[next] failed to validate auto vin for address search; updated autoSearchFields[hasError, errorMessage]`, {
                        isValid,
                        emittersAutoSearchFields: emitters.autoSearchFields,
                        emittersSession: emitters.session,
                        emittersPropertySearchEmit: emitters.propertySearchEmit,
                        emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                    });

                    this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                    emitters.autoSearchEmit.emit();

                    this.loggerService.error('TPI-PLDS-V2: Error Search Auto [Address]', {
                        UserId: emitters.session.context.session.account.shortName,
                        PolicyNumber: this.autoSearchFields.policyNumber,
                        Vin: fullVin,
                        ErrorMessage: this.autoSearchFields.errorMessage
                    });

                    this.window.waitIndicatorFullscreen.hide();
                }
                this.loggerService.debug('getAutoPolicyNumberAddressSearch() - exit subscribe[next]; hide wait Indicator', {
                    isValid,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });
                this.loggerService.logCurrentContents();
            },
            error: (error: HttpErrorResponse) => {
                this.loggerService.error('getAutoPolicyNumberAddressSearch() - enter subscribe[error]', {
                    error,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });

                this.disablePrint = true;
                emitters.disablePrintChange.emit(this.disablePrint);
                this.autoSearchFields.errorMessage = error.error?.developerMessage ?? tpiSearchError.UNKNOWN_APPLICATION_ERROR;
                this.autoSearchFields.hasError = true;

                this.loggerService.error(`getAutoPolicyNumberAddressSearch() - subscribe[error] failed to validate address and vin; updated autoSearchFields[hasError, errorMessage]`, {
                    error,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });

                this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                emitters.autoSearchEmit.emit();

                this.loggerService.error('TPI-PLDS-V2: Error Search Auto [Address]', {
                    UserId: emitters.session.context.session.account.shortName,
                    PolicyNumber: this.autoSearchFields.policyNumber,
                    Vin: this.autoSearchFields.vin,
                    ErrorMessage: this.autoSearchFields.errorMessage
                });

                this.window.waitIndicatorFullscreen.hide();

                this.loggerService.error('getAutoPolicyNumberAddressSearch() - exit subscribe[error]; hide wait Indicator', {
                    error,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });
                this.loggerService.logCurrentContents();
            }
        });
    }

    getAutoPolicyNumberPolicyVinSearch(policyNumber: string, sixDigitVin: string, emitters: PolicyEmitters): void {
        this.loggerService.info('getAutoPolicyNumberPolicyVinSearch() - Enter', {
            policyNumber,
            sixDigitVin,
            emittersAutoSearchFields: emitters.autoSearchFields,
            emittersSession: emitters.session,
            emittersPropertySearchEmit: emitters.propertySearchEmit,
            emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
        });

        this.autoSearchFields = emitters.autoSearchFields;
        emitters.showResultEmit.emit(false);

        this.loggerService.info('getAutoPolicyNumberPolicyVinSearch() - initalized autoSearchFields', {
            autoSearchFields: this.autoSearchFields
        });

        this.loggerService.info('Auto Search by policy number and vin started -', {
            policyNumber,
            sixDigitVin
        });

        this.ecif.validateAutoPolicyVin(policyNumber, sixDigitVin).subscribe({
            next: (policyVin: any) => {
                this.loggerService.info('getAutoPolicyNumberPolicyVinSearch() - Enter subscribe[next] valid policy number and 6 digits vin', {
                    policyVin,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit,
                    autoSearchFields: this.autoSearchFields
                });

                this.loggerService.info(
                    'TPI-PLDS-V2: Successful Search Auto [Policy-Vin]',
                    {
                        UserId: emitters.session?.context.session.account.shortName || '',
                        PolicyNumber: policyVin.policyNumber,
                        Vin: this.autoSearchFields.action.vin
                    }
                );

                this.fullVin = this.autoSearchFields.action.vin;
                this.autoSearchFields.action.displayResults = true;
                emitters.autoSearchFields.action.vin = policyVin?.vin;
                this.autoSearchFields.action.policyNumber =
                    policyVin?.policyNumber?.replace('%20', ' ');
                this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                emitters.autoSearchEmit.emit();
                this.disablePrint = false;
                emitters.disablePrintChange.emit(this.disablePrint);

                this.loggerService.info('getAutoPolicyNumberPolicyVinSearch() - Exit subscribe[next] validated policy-vin search; updated autoSearchFields[hasError, errorMessage]', {
                    policyVin,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit,
                    autoSearchFields: this.autoSearchFields
                });
                this.loggerService.logCurrentContents();
            },
            error: (error: HttpErrorResponse) => {
                this.loggerService.error('getAutoPolicyNumberPolicyVinSearch() - enter subscribe[error] invalid policy number or vin search', {
                    error,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });

                this.autoSearchFields.hasError = true;
                this.autoSearchFields.errorMessage = error.error?.developerMessage ?? tpiSearchError.UNKNOWN_APPLICATION_ERROR;

                if (!error.error?.developerMessage) {
                    this.loggerService.error('getAutoPolicyNumberPolicyVinSearch() - enter subscribe[error] back response type checking error ', {
                        error,
                        emittersAutoSearchFields: emitters.autoSearchFields,
                        emittersSession: emitters.session,
                        emittersPropertySearchEmit: emitters.propertySearchEmit,
                        emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                    });
                }

                this.disablePrint = true;
                emitters.disablePrintChange.emit(this.disablePrint);
                this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                emitters.autoSearchEmit.emit();

                this.loggerService.error('getAutoPolicyNumberPolicyVinSearch() - Exit subscribe[error] error validating policy-vin search; updated autoSearchFields[hasError, errorMessage]', {
                    error,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit,
                    autoSearchFields: this.autoSearchFields
                });

                this.loggerService.error(
                    'TPI-PLDS-V2: Error Search Auto [Policy-Vin]',
                    {
                        UserId: emitters.session.context.session.account.shortName,
                        PolicyNumber:
                            this.autoSearchFields.policyNumber,
                        ErrorMessage: this.autoSearchFields.errorMessage,
                        Vin: this.autoSearchFields.vin
                    }
                );

                this.window.waitIndicatorFullscreen.hide();

                this.loggerService.error('getAutoPolicyNumberPolicyVinSearch() - exit subscribe[error] policy-vin search; hide wait Indicator', {
                    error,
                    emittersAutoSearchFields: emitters.autoSearchFields,
                    emittersSession: emitters.session,
                    emittersPropertySearchEmit: emitters.propertySearchEmit,
                    emittersShowPropertySearchEmit: emitters.showPropertySearchEmit
                });
                this.loggerService.logCurrentContents();
            }
        });
    }

    getPropertySearchResults(policyNumber: string, zipCode: string, emitters: any): any {
        this.autoSearchFields = emitters.autoSearchFields;
        let mappedPolicyInformation = null;
        emitters.showPropertySearchEmit.emit(false);

        this.loggerService.info(
            'Property Search by policy number and zip code started -',
            {
                PolicyNumber: policyNumber,
                ZipCode: zipCode,
                UserId: sessionStorage.getItem('userName'),
                PolicySystem: 'Policy Center'
            }
        );

        this.ecif.validatePolicyWithEcifAgreement(policyNumber, zipCode, sessionStorage.getItem('jwt'))
            .pipe(switchMap((customerAgreement: CustomerAgreement) => {
                mappedPolicyInformation = this.policySearchAdapter.getAndMapPropertyInformation(customerAgreement, zipCode);
                return of(mappedPolicyInformation);
            }), catchError((error) => throwError(() => error)))
            .subscribe({
                next: (response) => {
                    if (response?.success) {
                        this.loggerService.info(
                            'Property Search success by policy number and zip code',
                            {
                                PolicyNumber: policyNumber,
                                ZipCode: zipCode,
                                UserId: sessionStorage.getItem('userName'),
                                PolicySystem: 'Policy Center',
                                response: response.success
                            }
                        );

                        const mappingResponse = response.success;
                        this.autoSearchFields.action.displayResults = true;
                        this.autoSearchFields.action.policyNumber = mappingResponse.policyNumber;
                        this.autoSearchFields.zipCode = mappingResponse.zipCode;
                        this.autoSearchFields.propertyType = mappingResponse.productType;
                        this.autoSearchFields.accountNumber = mappingResponse.billingAccountNumber;
                        this.autoSearchFields.ecn = mappingResponse.ecn;
                        this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                        emitters.propertySearchEmit.emit();
                        this.disablePrint = false;
                        emitters.disablePrintChange.emit(this.disablePrint);
                    } else if (response?.error) {
                        this.loggerService.error(
                            'Property Search by policy number and zip code failed',
                            {
                                PolicyNumber: policyNumber,
                                ZipCode: zipCode,
                                UserId: sessionStorage.getItem('userName'),
                                PolicySystem: 'Policy Center',
                                ErrorMessage: response.error
                            }
                        );

                        this.autoSearchFields.hasError = true;
                        this.autoSearchFields.errorMessage = response.error;
                        this.disablePrint = true;
                        emitters.disablePrintChange.emit(this.disablePrint);
                        this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                        emitters.propertySearchEmit.emit();
                        this.window.waitIndicatorFullscreen.hide();
                    }
                },
                error: (error) => {
                    this.loggerService.error(
                        'Property Search by policy number and zip code failed',
                        {
                            policyNumber,
                            zipCode,
                            response: error,
                            status: error.status
                        }
                    );

                    this.autoSearchFields.hasError = true;
                    if (error.status.toString() === commonConstants.ERROR_CONSTANTS.ERROR_403) {
                        this.autoSearchFields.errorMessage = this.policySearchAdapter.mapErrorResponse(tpiErrorTags.POLICY_INELIGIBLE).error;
                    } else if (error.status.toString() === commonConstants.ERROR_CONSTANTS.ERROR_404) {
                        this.autoSearchFields.errorMessage = this.policySearchAdapter.mapErrorResponse(tpiErrorTags.NULL_RESPONSE).error;
                    } else if (error.status.toString() === commonConstants.ERROR_CONSTANTS.ERROR_406) {
                        this.autoSearchFields.errorMessage = this.policySearchAdapter.mapErrorResponse(tpiErrorTags.POLICY_NOT_PROPERTY).error;
                    } else if (error.status.toString() === commonConstants.ERROR_CONSTANTS.ERROR_461) {
                        this.autoSearchFields.errorMessage = this.policySearchAdapter.mapErrorResponse(tpiErrorTags.POLICY_INFO_NOT_AVAILABLE_ONLINE).error;
                    } else {
                        this.autoSearchFields.errorMessage = this.policySearchAdapter.mapErrorResponse(tpiErrorTags.UNKNOWN_ERROR).error;
                    }

                    this.disablePrint = true;
                    emitters.disablePrintChange.emit(this.disablePrint);
                    this.storeStateSearchFields.updateSearchFields(this.autoSearchFields);
                    emitters.propertySearchEmit.emit();
                    this.window.waitIndicatorFullscreen.hide();
                },
                complete: () => {
                    this.loggerService.info(`Validate Policy With Ecif Agreement call Completed.`);
                }
            });
    }
}
