import { Injectable } from '@angular/core';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TpiOidcAuthConfigService {
    getConfig(): OpenIdConfiguration {
        return {
            authority: environment.PING.authorizeUrl,
            redirectUrl: encodeURI(environment.PING.redirectUri),
            autoUserInfo: false,
            postLogoutRedirectUri: encodeURI(environment.PING.logoutUrl),
            clientId: environment.authorize.client_id,
            silentRenew: false,
            scope: 'openid',
            responseType: 'id_token token',
            customParamsAuthRequest: {
                realm: 'business-partner',
                auth_method: 'ping',
                auth_id_spid: environment.PING.auth_id_spid,
                redirect_uri: encodeURI(environment.PING.redirectUri)
            }
        };
    }
}
