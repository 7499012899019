<form id="policyInquiryForm" *ngIf="autoSearchForm || propertySearchForm" aria-live="assertive">
    <bolt-tabset borderless activetab="{{ activeTab }}" class="noprint">
        <ng-container *ngIf="isPropertyAuthorized">
            <bolt-tablabel (click)="navigateToPropertyAuto('property')" selected="false">Property</bolt-tablabel>
            <bolt-tabpanel open="false">
                <div>
                    <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage">
                    </app-search-error-panel>
                </div>
                <h2 class="bolt-heading-md">Policy Inquiry</h2>
                <ng-container *ngIf="propertySearchForm">
                    <tpi-property-search [submitOptions]="submitOptions" [parentFormGroup]="propertySearchForm"></tpi-property-search>
                </ng-container>
            </bolt-tabpanel>
        </ng-container>
        <ng-container *ngIf="isAutoAuthorized">
            <bolt-tablabel (click)="navigateToPropertyAuto('auto')">Auto</bolt-tablabel>
            <bolt-tabpanel>
                <app-search-error-panel [hasError]="hasError" [errorMessage]="errorMessage"></app-search-error-panel>
                <h2 class="bolt-heading-md">Policy Inquiry</h2>
                <ng-container *ngIf="autoSearchForm">
                    <div [formGroup]="autoSearchForm" class="bolt-space-bottom-lg">
                        <bolt-radio-group label="Do you have the policy number?" ngDefaultControl [formControlName]="'hasPolicyNumber'" value="1" horizontal optionaltext="hide">
                            <bolt-radio value="1">Yes</bolt-radio>
                            <bolt-radio value="2">No</bolt-radio>
                        </bolt-radio-group>
                    </div>
                    <div>
                        <app-auto-search [submitOptions]="submitOptions" *ngIf="hasPolicyNumber()" [parentForm]="autoSearchForm"></app-auto-search>
                        <app-auto-no-search [submitOptions]="submitOptions" *ngIf="doesNotHavePolicyNumber()" [parentForm]="autoSearchForm" (autoNoSearchEmit)="setAutoSearch($event)"
                            [(isResetNoSearch)]="isResetNoAutoSearch"></app-auto-no-search>
                    </div>
                </ng-container>
            </bolt-tabpanel>
        </ng-container>
    </bolt-tabset>
    <app-search-results [showAutoResult]="showAutoResult" [showPropertyResult]="showPropertyResult" (showAutoResultEmit)="displayResultAuto($event)"
        (showPropertySearchEmit)="displayResultProperty($event)" (editFlowError)="editLienHolderFailure($event)">
    </app-search-results>
    <app-button-bar #buttonBar class="noprint" [isActiveTab]="selectedTab" [searchFormValues]="getSearchForm()" [disablePrint]="disablePrint" (resetSearchForm)="resetForm($event)"
        (showResults)="showResults($event)" (performValidationEmit)="performValidation($event)" (setSearch)="setSearch($event)">
    </app-button-bar>
</form>
<bolt-waiting-indicator id="policyRedirectWait" fullscreen starthidden></bolt-waiting-indicator>