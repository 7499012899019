<div *ngIf="session" class="side-nav">
    <div class="link-container">
        <a id="linkPolicyInquiry" [routerLink]="['policy-search']" [routerLinkActive]="['side-nav-active']">
            Policy Inquiry
        </a>
    </div>

    <div class="link-container">
        <a id="linkBulkLien" [routerLink]="['mortgage-bulk-update']" [routerLinkActive]="['side-nav-active']">
            Mortgage Bulk Update
        </a>
    </div>
    <div *ngIf="session.context.session.account.role.id == manageUser" class="link-container">
        <a id="linkManageUser" [routerLink]="['/manage-users']" [routerLinkActive]="['side-nav-active']">
            Manage User Accounts
        </a>
    </div>

    <div *ngIf="session.context.session.account.role.id == manageCompany" class="link-container">
        <a id="linkCompany" [routerLink]="['/company-list']" [routerLinkActive]="['side-nav-active']">
            Manage Company List
        </a>
    </div>

    <div *ngIf="session.context.session.account.role.id == manageWeb" class="link-container">
        <a id="linkWebSupport" [routerLink]="['/web-support']" [routerLinkActive]="['side-nav-active']">
            Manage User - Web
        </a>
    </div>

    <div class="link-container">
        <a id="linkChangePassword" [routerLink]="['password-change']" [routerLinkActive]="['side-nav-active']">
            Change Password
        </a>
    </div>
</div>