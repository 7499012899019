import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RxFormHelperService } from '@nationwide/rx-form-helper';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { CONST_SIZES, customErrorFlags, VALIDATION_REGEX } from '../../CONSTANTS/tpi-error-tags';
import { ValidatorService } from '../../shared/services/validator.service';
import { EditMortgageServiceRequest } from '../../tpi-models/general/edit-mortgage-services-request';
import { SearchFields } from '../../tpi-models/general/search-forms';
import { UserSession } from '../../tpi-models/general/tpisession';
import { LoggerService } from '../../tpi-services/logger/logger.service';
import { PolicySearchStateServiceService } from '../../tpi-services/tpi-statemanagement/policy-search-state-service.service';
import { SearchResultsComponent } from './shared-components/search-results/search-results.component';

@Component({
    selector: 'tpi-policy-inquiry',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './policy-inquiry.component.html'
})
export class PolicyInquiryComponent implements OnInit {
    @ViewChild('buttonBar') buttonBar;
    @ViewChild(SearchResultsComponent) resultComponent: SearchResultsComponent;
    @Output() showAutoResult: boolean;
    @Output() showPropertyResult: boolean;
    activeTab = 0; // For testing purposes
    autoSearchForm: UntypedFormGroup;
    disablePrint = false;
    editMortgageServiceRequest: EditMortgageServiceRequest = {};
    errorMessage: string;
    hasError: boolean;
    hasPolicyNumberValue = '1';
    isAutoAuthorized = false;
    isPropertyAuthorized = false;
    propertySearchForm: UntypedFormGroup;
    searchFields: SearchFields;
    selectedTab = commonConstants.PROPERTY_FLOW;
    showAutoYesSearch: boolean;
    submitOptions = {
        onSubmit: true,
        submitStatus: false
    };
    // eslint-disable-next-line max-params
    constructor(
        public formHelper: RxFormHelperService,
        private validatorService: ValidatorService,
        private titleService: Title,
        private loggerService: LoggerService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private storeSearchFields: PolicySearchStateServiceService
    ) { }

    displayResultAuto(event: boolean): void {
        this.showAutoResult = event;
        if (this.showAutoResult) {
            this.buttonBar.hideWaitIndicator();
        }
    }

    displayResultProperty(event: boolean): void {
        this.showPropertyResult = event;
    }

    doesNotHavePolicyNumber(): boolean {
        return this.hasPolicyNumberValue === '2';
    }

    editLienHolderFailure(eventLienFeedback): void {
        this.hasError = eventLienFeedback.hasError;
        this.errorMessage = eventLienFeedback.errorMessage;
        this.focusError();
    }

    getSearchForm(): UntypedFormGroup {
        if (this.isPropertyAndAuto()) {
            if (this.selectedTab === commonConstants.PROPERTY_FLOW) {
                this.titleService.setTitle('Property Policy Search - Nationwide');
                return this.propertySearchForm;
            } else {
                this.titleService.setTitle('Auto Policy Search - Nationwide');
                return this.autoSearchForm;
            }
        } else if (this.isPropertyTabOnly()) {
            this.titleService.setTitle('Property Policy Search - Nationwide');
            return this.propertySearchForm;
        } else {
            this.titleService.setTitle('Auto Policy Search - Nationwide');
            return this.autoSearchForm;
        }
    }

    hasPolicyNumber(): boolean {
        return this.hasPolicyNumberValue === '1';
    }

    navigateToPropertyAuto(tab: string): void {
        if (tab !== this.selectedTab) {
            this.hasError = false;
            this.resultComponent.resetResultData();
            this.initializeSearchFields();
            this.submitOptions.submitStatus = false;
            this.disablePrint = true;
            this.selectedTab = tab;
            this.autoSearchForm = undefined;
            this.propertySearchForm = undefined;
            this.showPropertyResult = false;
            this.showAutoResult = false;
            if (tab === commonConstants.PROPERTY_FLOW) {
                this.buildPropertyForm();
            } else {
                this.buildAutoForm();
            }
            this.storeSearchFields.updateSearchFields(this.searchFields);
        }
    }

    ngOnInit(): void {
        this.setAuthorization();
        this.logTabInformation();
        this.initializeFocusedTab();
        this.showAutoResult = false;
        this.showPropertyResult = false;
        this.initializeSearchFields();
        this.storeSearchFields.getSearchFields().subscribe((searchFields: SearchFields) => {
            this.searchFields = searchFields;
        });
        if (this.selectedTab === commonConstants.PROPERTY_FLOW) {
            this.buildPropertyForm();
        } else {
            this.buildAutoForm();
        }
    }

    performValidation(event?): void {
        this.submitOptions = {
            onSubmit: true,
            submitStatus: true
        };
    }

    resetForm($event?): void {
        if ($event) {
            if (this.isFormAuto()) {
                this.resultComponent.resetResultData();
                this.showAutoResult = false;
                this.autoSearchForm.updateValueAndValidity();
                this.resetAutoValues();
            } else {
                this.showPropertyResult = false;
                this.resultComponent.resetResultPropertyData();
                this.propertySearchForm.updateValueAndValidity();
                this.resetPropertyValues();
            }
            this.submitOptions.submitStatus = false;
            this.hasError = false;
        }
    }

    setSearch(): void {
        this.hasError = this.searchFields.hasError;
        this.errorMessage = this.searchFields.errorMessage;
        if (this.hasError) {
            this.focusError();
            return;
        }
        if (this.isFormAuto()) {
            this.showAutoResults();
        } else if (this.isFormProperty() && this.propertySearchForm?.valid) {
            this.showResultsProperty();
        } else {
            return;
        }
    }

    showResults(event: boolean): void {
        if (this.isFormAuto()) {
            this.showAutoResult = event;
            if (this.showAutoResult) {
                this.buttonBar.hideWaitIndicator();
            }
        } else {
            this.showPropertyResult = event;
        }
    }

    private buildAutoForm(): void {
        this.hasPolicyNumberValue = '1';
        this.autoSearchForm = this.formHelper.build({
            hasPolicyNumber: {
                where: (form: UntypedFormGroup): any => this.isFormAuto(),
                control: ['1', Validators.compose([Validators.required])]
            },
            policyNumber: {
                where: (form: UntypedFormGroup): any => this.hasPolicyNumber(),
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(CONST_SIZES.POLICYNUMBER_MAX_LENGTH),
                        this.validatorService.validatePolicy(VALIDATION_REGEX.isPolicyNumber)
                    ])
                ]
            },
            sixDigitVinNumber: {
                where: (form: UntypedFormGroup): any => this.hasPolicyNumber(),
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        this.validatorService.validateVinWithSpecifiedDigit(CONST_SIZES.VIN_SHORT_MIN_LENGTH),
                        Validators.pattern(VALIDATION_REGEX.isAlphaNumeric)
                    ])
                ]
            },
            streetAddress: {
                where: (form: UntypedFormGroup): any => this.doesNotHavePolicyNumber(),
                control: ['', Validators.compose([Validators.required])]
            },
            city: {
                where: (form: UntypedFormGroup): any => this.doesNotHavePolicyNumber(),
                control: ['', Validators.compose([Validators.required])]
            },
            state: {
                where: (form: UntypedFormGroup): any => this.doesNotHavePolicyNumber(),
                control: ['', Validators.compose([Validators.required])]
            },
            zipCode: {
                where: (form: UntypedFormGroup): any => this.doesNotHavePolicyNumber(),
                control: ['', Validators.compose([Validators.required, Validators.pattern(VALIDATION_REGEX.isZipCode)])]
            },
            fullVin: {
                where: (form: UntypedFormGroup): any => this.doesNotHavePolicyNumber(),
                control: [
                    '',
                    Validators.compose([
                        Validators.required,
                        this.validatorService.validateFullVin(VALIDATION_REGEX.isAlphaNumeric)
                    ])
                ]
            }
        });

        this.autoSearchForm.get('hasPolicyNumber').valueChanges.subscribe((newValue) => {
            this.submitOptions.submitStatus = false;
            this.hasPolicyNumberValue = newValue;
            this.resetAutoValues();
            this.showAutoResult = false;
            this.disablePrint = true;
        });
        this.autoSearchForm.markAsTouched();
    }

    private buildPropertyForm(): void {
        this.propertySearchForm = this.formHelper.build({
            policyNumber: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(CONST_SIZES.POLICYNUMBER_MAX_LENGTH),
                    this.validatorService.validatePolicy(VALIDATION_REGEX.isPolicyNumber)
                ])
            ],
            zipCode: [
                '',
                Validators.compose([
                    Validators.required,
                    this.validatorService.validateWithCustomError(
                        VALIDATION_REGEX.isZipCode,
                        customErrorFlags.ZIP_CODE_PROPERTY_ERROR
                    )
                ])
            ]
        });
        this.propertySearchForm.markAsTouched();
    }

    private focusError(): void {
        if (isPlatformBrowser(this.platformId) && this.hasError) {
            this.scrollIntoView();
        }
    }

    private initializeFocusedTab(): void {
        if (this.isAutoTabOnly() || this.activeTab === 1) {
            this.selectedTab = commonConstants.AUTO_FLOW;
        } else {
            this.selectedTab = commonConstants.PROPERTY_FLOW;
        }
    }

    private initializeSearchFields(): void {
        const baseSearchFields: SearchFields = {
            policyNumber: '',
            vin: '',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            fullVin: '',
            hasError: false,
            errorMessage: '',
            invalidForm: true,
            isYesSearch: true,
            isNoSearch: false,
            action: {
                displayResults: false,
                vin: '',
                policyNumber: ''
            },
            isPropertySearch: false
        };
        this.storeSearchFields.updateSearchFields(baseSearchFields);
    }

    private isAutoTabOnly(): boolean {
        return this.isAutoAuthorized && !this.isPropertyAuthorized;
    }

    private isFormAuto(): boolean {
        return this.selectedTab === commonConstants.AUTO_FLOW;
    }

    private isFormProperty(): boolean {
        return this.selectedTab === commonConstants.PROPERTY_FLOW;
    }

    private isPropertyAndAuto(): boolean {
        return this.isAutoAuthorized && this.isPropertyAuthorized;
    }

    private isPropertyTabOnly(): boolean {
        return !this.isAutoAuthorized && this.isPropertyAuthorized;
    }

    private logTabInformation(): void {
        this.loggerService.info(`User policy access: Property - ${this.isPropertyAuthorized}, Auto - ${this.isAutoAuthorized}`);
        if (!this.isAutoAuthorized && !this.isPropertyAuthorized) {
            this.loggerService.error(`User does not have access to Auto and Property`);
            this.loggerService.logCurrentContents();
        } else {
            this.loggerService.info(`Current active Policy Inquiry tab: ${this.selectedTab}`);
        }
    }

    private resetAutoValues(): void {
        this.autoSearchForm.get('policyNumber').setValue('');
        this.autoSearchForm.get('sixDigitVinNumber').setValue('');
        this.autoSearchForm.get('streetAddress').setValue('');
        this.autoSearchForm.get('city').setValue('');
        this.autoSearchForm.get('state').setValue('');
        this.autoSearchForm.get('zipCode').setValue('');
        this.autoSearchForm.get('fullVin').setValue('');
        this.hasError = false;
    }

    private resetPropertyValues(): void {
        this.propertySearchForm.get('policyNumber').setValue('');
        this.propertySearchForm.get('zipCode').setValue('');
        this.hasError = false;
    }

    private scrollIntoView(): void {
        const formElement = document.getElementById('policyInquiryForm');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    private setAuthorization(): void {
        const session: UserSession = sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : null;
        if (session?.context?.session?.account?.company) {
            this.isAutoAuthorized = session.context.session.account.company.autoAuthorization === 1;
            this.isPropertyAuthorized = session.context.session.account.company.propertyAuthorization === 1;
        }
    }

    private showAutoResults(): void {
        if (!this.resultComponent) {
            this.showAutoResult = false;
            return;
        }
        if (this.searchFields.action.displayResults) {
            this.disablePrint = false;
            this.resultComponent.resetResultData();
            this.resultComponent.showAutoResults(this.searchFields);
        }
    }

    private showResultsProperty(): void {
        if (!this.resultComponent) {
            this.showPropertyResult = false;
        } else if (this.searchFields.action.displayResults) {
            this.resultComponent.resetResultPropertyData();
            this.resultComponent.showPropertyResults(this.searchFields);
        }
    }
}
