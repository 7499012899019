import { Injectable } from '@angular/core';
import { PropertyCoveragesRequestsCommonService, PropertyRequestsCommonService } from '@nationwide/dgs-internet-servicing-policy-requests';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { commonConstants } from '../../CONSTANTS/tpi-common-constants';
import { LoggerService } from '../logger/logger.service';
import { PolicySearchResultsAdapterService } from './policy-search-results-adapter.service';

@Injectable({
    providedIn: 'root'
})
export class RenewalsService {
    // eslint-disable-next-line max-params
    constructor(
        private propertyRequestsCommon: PropertyRequestsCommonService,
        private propertyCoveragesCommon: PropertyCoveragesRequestsCommonService,
        private loggerService: LoggerService,
        private policySearchResultsAdapter: PolicySearchResultsAdapterService) { }

    getPropertyRenewals(policyNumber: string, consumerData: any, propertyOptions: any): Observable<any> {
        return this.propertyRequestsCommon.retrievePropertyRenewals(policyNumber, consumerData, propertyOptions).pipe(switchMap((propertyRenewalResponse) => {
            this.loggerService.info('Success policy renewel API - retrievePropertyRenewals()',
                { policyNumber, propertyRenewalResponse });
            const renewingRenewalInformation = propertyRenewalResponse?.length > 0 ? propertyRenewalResponse.filter((renewalInformation) => renewalInformation.status === commonConstants.POLICY_STATUS_BOUND) : [];
            const renewalID = renewingRenewalInformation[0]?.renewalId;
            if (renewalID) {
                return this.propertyCoveragesCommon.retrievePolicyCoveragesByRenewalID(renewalID, consumerData, propertyOptions).pipe(switchMap((response) => {
                    if (response) {
                        this.loggerService.info('Success policy coverages renewal API - retrievePolicyCoveragesByRenewalID()', { renewalID, response });
                        const renewalInformation = this.policySearchResultsAdapter.mapPropertyEndorsements(response, propertyOptions.productType);
                        renewalInformation.renewalEffectiveDate = renewingRenewalInformation[0]?.effectiveDate;
                        return of(renewalInformation);
                    } else {
                        this.loggerService.info('Error retreiving responses from renewal API - retrievePolicyCoveragesByRenewalID()', { renewalID, response });
                        return of({});
                    }
                }), catchError((error) => {
                    this.loggerService.error('Error policy coverages renewal API - retrievePolicyCoveragesByRenewalID()', { renewalID, error });
                    return of(error);
                }));
            } else {
                this.loggerService.info('Error retreiving renewal ID - retrievePolicyCoveragesByRenewalID()');
                return of({});
            }
        }),
            catchError((error) => {
                this.loggerService.error('Error policy renewal API - retrievePropertyRenewals()', { policyNumber, error });
                return of(error);
            }));
    }
}
